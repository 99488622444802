import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { firstValueFrom, map, take } from 'rxjs';
import { EventDataService } from 'nexus-core';
import { isDemo } from 'nexus-core';

export const eventExists: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const eventDataService = inject(EventDataService);
  const eventKey = route.paramMap.get('eventKey');

  if (!eventKey) return false;

  const name = await firstValueFrom(eventDataService.getEventName(eventKey));
  if (name) {
    return true;
  }

  if(!isDemo(eventKey) && !/^\d{4}/.test(eventKey)) {
    const eventKeyWithYear = `${new Date().getUTCFullYear()}${eventKey}`;
    return router.parseUrl(state.url.replace(`/event/${eventKey}`, `/event/${eventKeyWithYear}`));
  }

  console.warn('Event not found', eventKey);
  return router.parseUrl(`/error?message=Event not found&details=${eventKey}`);
};

export const eventNotEnded: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const eventDataService = inject(EventDataService);
  const eventKey = route.paramMap.get('eventKey') || '';
  const router = inject(Router);

  return eventDataService.hasEventEnded(eventKey).pipe(
    take(1),
    map((e) => {
      if (!e) return true;

      return router.parseUrl(`/event/${eventKey}/event-ended`);
    })
  );
};

export const inviteUrlIsValid: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const key = route.queryParamMap.get('key');

  if (key) return true;

  return router.parseUrl(`/error?message=Invalid invite URL`);
};

import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { switchMap, of, firstValueFrom, catchError, map } from "rxjs";
import { EventDataService } from 'nexus-core';
import { AuthzService } from "./authz.service";
import { Auth, user } from "@angular/fire/auth";

export const hasPermission: (permission: string) => CanActivateFn = (permission: string) =>
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const authzService = inject(AuthzService);
    const eventDataService = inject(EventDataService);
    const eventKey = route.paramMap.get('eventKey');

    if(!eventKey) return false;

    return authzService.isAuthorized(eventKey, permission).pipe(
      switchMap(async isAuthorized => {
        if (isAuthorized) return true;

        const name = await firstValueFrom(eventDataService.getEventName(eventKey));
        if(!name) {
          console.warn('Event not found', eventKey);
          return router.parseUrl(`/error?message=Event not found&url=${getResolvedUrl(route)}`);
        }

        return router.parseUrl(`/login?event=${eventKey}&permission=${permission}&url=${getResolvedUrl(route)}`);
      }),
      catchError(() => {
        return of(router.parseUrl(`/error?url=${getResolvedUrl(route)}`));
      }),
    );
  };

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
}

export const isLoggedIn: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const auth = inject(Auth);
    const router = inject(Router);

    return user(auth).pipe(
      map(u => {
        if(u) return true;

        return router.parseUrl(`/login?redirect=${encodeURIComponent(state.url)}`);
      }),
    );
  };

export const isSuperAdmin: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const db = inject(AuthzService);

    return db.isSuperAdmin();
  };

export const isLoggedInNonAnon: CanActivateFn =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const auth = inject(Auth);
    const router = inject(Router);

    return user(auth).pipe(
      map(u => {
        if(u && !u.isAnonymous) return true;

        return router.parseUrl(`/login?redirect=${encodeURIComponent(state.url)}&nonAnon=true`);
      }),
    );
  };